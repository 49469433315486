import { useMemo } from 'react';
import { getAgentDetailsByEnglishName } from 'valorant-tools';
import IMG_INFO_ICON from './info-solid.svg';
import './nomedia.css';

const NoMedia = ({ agent }) => {
  const agentImage = useMemo(() => {
    const agentDetails = getAgentDetailsByEnglishName(agent);
    if (agentDetails != null) {
      return agentDetails.img;
    }
  }, [agent]);
  return (
    <div className="widget nomedia">
      <div>
        {agentImage != null && (
          <img className="agent" src={agentImage} alt={`Agent: ${agent}`} />
        )}
        <div className="comment">
          <div>
            <img className="icon-info" src={IMG_INFO_ICON} alt="Info" />
          </div>
          <div>
            Me and the Team are working hard to bring you as much content as
            possible, but for now please change your filters.
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMedia;
