import { Fragment, useMemo } from 'react';
import VideoLoading from '../VideoLoading';
import Favourite from '../Favourite';
import {
  getPositionLabel,
  getAgentMediaAbility,
  getAgentAbilityImage,
} from './utils';
import SVGLock from './Lock.jsx';
import IMG_Thumbnail_Placeholder from './thumbnail-placeholder.png';
import './thumbnailview.css';

const Ability = ({ agent, ability }) => (
  <div
    className={`ab-img ${ability.toLowerCase()}`}
    style={{
      backgroundImage: `url(${getAgentAbilityImage(agent, ability)})`,
    }}
  />
);

const Item = ({
  selectedId,
  setSelectedId,
  media,
  watched,
  favourites,
  removeFavourite,
  addFavourite,
  isSubscribed,
}) => {
  const screenshot = useMemo(() => {
    if (media.thumbnails != null && media.thumbnails.length > 0) {
      const screenshot640 = media.thumbnails.find(
        (sc) => sc.size === '640x360'
      );
      if (screenshot640 != null) {
        return screenshot640.source;
      } else {
        return media.thumbnails[0].source;
      }
    }
    return IMG_Thumbnail_Placeholder;
  }, [media]);
  return (
    <li
      onClick={() => setSelectedId(media.id)}
      className={media.id === selectedId ? `active` : ``}
    >
      <div className="container">
        <div className="sc-holder">
          <div
            className={`locked-content ${
              media.isFree || isSubscribed ? '' : 'locked'
            }`}
          >
            <div className="img-holder">
              <SVGLock />
              <p className="sub-only">Subscriber only content</p>
            </div>
          </div>
          {watched.includes(media.id) === false && (
            <div className="new-media">NEW</div>
          )}
          <Ability agent={media.agent} ability={media.ability} />
          {screenshot != null && (
            <img loading="lazy" src={screenshot} alt="lineup thumbnail" />
          )}
        </div>
        <footer>
          <div>
            <div className="is-fav">
              <Favourite
                isFavourite={favourites.includes(media.id)}
                onClick={
                  favourites.includes(media.id)
                    ? () => removeFavourite(media.id)
                    : () => addFavourite(media.id)
                }
              />
            </div>
          </div>
          <div>
            <h2>{media.title}</h2>
            <p>
              {getAgentMediaAbility(media)}, {getPositionLabel(media.position)},{' '}
              {media.side === 'attack' ? 'Attack' : 'Defense'}
            </p>
          </div>
        </footer>
      </div>
    </li>
  );
};

const ThumbnailView = ({
  isLoading,
  isNoResult,
  media,
  selectedId,
  setSelectedId,
  isSubscribed,
  watched,
  favourites,
  removeFavourite,
  addFavourite,
}) => (
  <Fragment>
    {isLoading && <VideoLoading />}
    {isLoading === false && isNoResult && (
      <p className="no-media">
        No media found.
        <br />
        Please change your filters.
      </p>
    )}
    <div className="thumbnailview">
      {isLoading === false && isNoResult === false && (
        <ul>
          {media.map((media, index) => (
            <Item
              selectedId={selectedId}
              key={`${media.agent}-${index}`}
              media={media}
              watched={watched}
              favourites={favourites}
              removeFavourite={removeFavourite}
              addFavourite={addFavourite}
              setSelectedId={setSelectedId}
              isSubscribed={isSubscribed}
            />
          ))}
        </ul>
      )}
    </div>
  </Fragment>
);

export default ThumbnailView;
