import CONFIG from '../../../config/config.json';

const { API_URL } = CONFIG;

const postLogin = (username, password) =>
  new Promise((resolve) => {
    fetch(`${API_URL}/web/login`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          resolve(false);
        }
      })
      .then((data) => {
        if (data != null) {
          resolve(data);
        } else {
          resolve({
            success: false,
            message: 'Login failed, unknown error.',
          });
        }
      })
      .catch((e) => {
        resolve({
          success: false,
          message: 'Login failed, unknown error.',
        });
      });
  });

const postSignup = (username, password) =>
  new Promise((resolve) => {
    fetch(`${API_URL}/web/signup`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          resolve(false);
        }
      })
      .then((data) => {
        if (data != null) {
          resolve(data);
        } else {
          resolve({
            success: false,
            message: 'Login failed, unknown error.',
          });
        }
      })
      .catch((e) => {
        resolve({
          success: false,
          message: 'Login failed, unknown error.',
        });
      });
  });

const getTokenValidation = (token) =>
  new Promise((resolve) => {
    fetch(`${API_URL}/web/user`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          resolve(false);
        }
      })
      .then((data) => {
        if (data != null && data.success === true) {
          resolve({
            success: true,
            user: data.user,
          });
        } else {
          resolve({
            success: false,
          });
        }
      })
      .catch((e) => {
        resolve({
          success: false,
        });
      });
  });

export { postLogin, postSignup, getTokenValidation };
