import { useMemo } from 'react';
import './creator.css';
import IMG_FB from './facebook.svg';
import IMG_Twitch from './twitch.svg';
import IMG_Youtube from './youtube.svg';

const getImgByLinkType = (linkType) => {
  switch (linkType) {
    case 'youtube':
      return IMG_Youtube;
    case 'twitch':
      return IMG_Twitch;
    case 'facebook':
      return IMG_FB;
    default:
      return false;
  }
};

const Creator = ({ creator }) => {
  const isCreator = useMemo(
    () => creator != null && creator.link != null,
    [creator]
  );
  const imgSrc = useMemo(() => getImgByLinkType(creator.linkType), [creator]);

  return isCreator ? (
    <a
      href={creator.link}
      className="widget author"
      target="_blank"
      rel="noreferrer"
    >
      <p className="created-by">Created By</p>
      {imgSrc !== false && <img src={imgSrc} alt="social icon" />}
      <p className="creator">{creator.name}</p>
    </a>
  ) : null;
};

export default Creator;
