import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useLocation } from 'react-router-dom';
import { fetchMarkdown } from './utils';
import Ad from './Ad';
import './Markdown/markdown.css';

function imageRenderer({ src, ...props }) {
  const regExp = new RegExp(/(.*)=([0-9]{0,4})x([0-9]{0,4})/g);
  let width = undefined;
  let height = undefined;
  let finalSrc = src;
  const match = regExp.exec(src);
  if (match != null) {
    if (match['2'] != null) {
      width = match['2'];
    }
    if (match['3'] != null) {
      height = match['3'];
    }
    finalSrc = match['1'];
  }

  return <img alt="" src={finalSrc} width={width} height={height} {...props} />;
}

const PageMarkddown = ({ file }) => {
  const [markdown, setMarkdown] = useState('');
  let location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMarkdown(file);
      setMarkdown(data);
    };
    fetchData();
  }, [file]);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <div className="content page markdown">
      <div className="widget markdown">
        <Ad adFormat="narrow" isBorderless={true} />
        <ReactMarkdown
          components={{
            img: imageRenderer,
          }}
          transformImageUri={null}
          remarkPlugins={[remarkGfm]}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default PageMarkddown;
