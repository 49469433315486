import IMG_Babies from './babies.png';
import IMG_INFO_ICON from '../home/NoMedia/info-solid.svg';
import Button from '../home/Button';
import './index.css';

const AdBlock = () => (
  <div className="widget adblock">
    <div className="container">
      <div className="comment">
        <div>
          <img className="icon-info" src={IMG_INFO_ICON} alt="Info" />
        </div>
        <div className="text">
          <p>Dear Agent! </p>
          <p>
            We feel responsible for training the babies well.{' '}
            <strong>Please disable AdBlock</strong> for our site, so we can
            succeed in our mission.
          </p>
          <p>Thank you!</p>
        </div>
      </div>
      <img className="babies" src={IMG_Babies} alt="Valorant babies" />
      <div className="actions">
        <Button
          href="https://www.google.com/search?q=how+to+disable+adblock+for+a+website"
          target="_blank"
          rel="noreferrer"
        >
          How to disable it?
        </Button>
        <Button onClick={() => window.location.reload()}>
          Okey, I disabled it.
        </Button>
      </div>
    </div>
  </div>
);

export default AdBlock;
