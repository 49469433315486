import { useState, useEffect, useCallback } from 'react';
//import useRouter from 'utils/hooks/useRouter';
import { useLocation } from 'react-router-dom';
import useLocalStorage from '../useLocalStorage.js';
import { getTokenValidation } from './utils';

const useAuthService = () => {
  const [token, setToken] = useLocalStorage('token', null);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const pathname = location.pathname;
  const sessionCleanup = useCallback(() => {
    setIsAuthenticated(false);
    setToken(null);
    setUser(null);
  }, [setToken, setIsAuthenticated, setUser]);

  const checkToken = useCallback(
    async (token) => {
      if (token != null) {
        const { success: isAuthenticated, user } = await getTokenValidation(
          token
        );
        if (isAuthenticated) {
          setIsAuthenticated(true);
          setUser(user);
        } else {
          sessionCleanup();
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    },
    [sessionCleanup, setUser, setIsAuthenticated]
  );

  const [savedPathname, setPathname] = useState();
  useEffect(() => {
    if (savedPathname !== pathname) {
      setPathname(pathname);
      checkToken(token);
    }
  }, [pathname, savedPathname, checkToken, token, setPathname]);

  const login = (ssoUrl) => {
    if (ssoUrl != null) {
      window.location.href = ssoUrl;
    }
  };

  const logout = useCallback(async () => {
    sessionCleanup();
  }, [sessionCleanup]);

  const callback = useCallback(
    (newToken) => {
      if (newToken != null && newToken !== token) {
        setToken(newToken);
        setIsLoading(true);
        checkToken(newToken);
      }
    },
    [setToken, setIsLoading, token, checkToken]
  );

  return {
    isLoading,
    isAuthenticated,
    token,
    login,
    logout,
    callback,
    user,
  };
};

export default useAuthService;
