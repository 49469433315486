import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import Header from './Header';
import AuthServiceProvider from './Auth';
import './index.css';
import './basics.css';
import PageHome from './PageHome';
import PageMarkdown from './PageMarkdown';
import PageAgents from './PageAgents';
import markdowns from './Markdown/pages.json';
import Tags from './Tags';
import Footer from './Footer';

ReactGA.initialize('UA-225875090-1');

const Home = () => (
  <AuthServiceProvider>
    <div className="all-container">
      <Header />
      <Switch>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/:agent/:map/:ability" component={PageHome} />
        <Route path="/login/:userName?" component={() => <Redirect to="/" />} />
        <Route path={`/agent/:agent`}>
          <PageAgents />
        </Route>
        {markdowns.map(({ url, file }, index) => (
          <Route key={index} path={url}>
            <PageMarkdown file={file} />
          </Route>
        ))}
      </Switch>
      <Tags />
      <Footer map="Bind" />
    </div>
  </AuthServiceProvider>
);

export default Home;
