import { getAgentDetailsByEnglishName } from 'valorant-tools';
import { capitalize } from '../utils';
import IMG_BOMB from '../Abilities/Bomb.png';

const getPositionLabel = (position) => {
  if (['A', 'B', 'C'].includes(position)) {
    return `${position} Site`;
  }
  return position;
};

const getAgentMediaAbility = (media) => {
  if (media.ability != null) {
    const agentDetails = getAgentDetailsByEnglishName(media.agent);
    if (agentDetails != null) {
      const agentAbility = agentDetails.abilities.find(
        ({ key }) => key === media.ability
      );
      if (agentAbility != null) {
        return `${media.ability} - ${capitalize(agentAbility.label, true)}`;
      }
    }
    if (media.ability === 'setups') {
      return 'Setups and Executes';
    }
  }
  return media.ability;
};

const getAgentAbilityImage = (agent, ability) => {
  const agentDetails = getAgentDetailsByEnglishName(agent);
  if (agentDetails != null) {
    const agentAbility = agentDetails.abilities.find(
      ({ key }) => key === ability
    );
    if (agentAbility != null) {
      return agentAbility.img;
    }
  }
  return IMG_BOMB;
};

export { getPositionLabel, getAgentMediaAbility, getAgentAbilityImage };
