import { useCallback } from 'react';
import ReactGA from 'react-ga';
import useLocalStorage from '../useLocalStorage';
import ListView from './ListView';
import ThumbnailView from './ThumbnailView';
import './media.css';

const Media = ({
  toggleSidebar,
  isSubscribed,
  isLoading,
  isNoResult,
  media,
  favourites,
  addFavourite,
  removeFavourite,
  selectedId,
  setSelectedId,
  watched,
}) => {
  const [view, setView] = useLocalStorage('media-view', 'thumbnail');

  const handleViewChange = useCallback(
    (v) => {
      setView(v);
      ReactGA.event({
        category: 'User',
        action: 'User changed list view.',
        label: v,
      });
    },
    [setView]
  );

  return (
    <div className="widget media">
      <div className="media-header">
        <div className="media-text">
          <p>Media</p>
        </div>
        <div className="switch-sort">
          <div className={`btn${view === 'thumbnail' ? ` active` : ``}`}>
            <button onClick={() => handleViewChange('thumbnail')}>
              <svg width="18" viewBox="0 0 512 512">
                <path d="M192 176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144C170.5 32 192 53.49 192 80V176zM192 432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144C170.5 288 192 309.5 192 336V432zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM448 432C448 458.5 426.5 480 400 480H304C277.5 480 256 458.5 256 432V336C256 309.5 277.5 288 304 288H400C426.5 288 448 309.5 448 336V432z"></path>
              </svg>
            </button>
          </div>
          <div className={`btn${view === 'list' ? ` active` : ``}`}>
            <button onClick={() => handleViewChange('list')}>
              <svg width="18" viewBox="0 0 512 512">
                <path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="btn close">
          <button onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
            >
              <path d="M448 32C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
            </svg>
            <span>Close</span>
          </button>
        </div>
      </div>
      {view === 'list' && (
        <ListView
          isLoading={isLoading}
          isNoResult={isNoResult}
          media={media}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          isSubscribed={isSubscribed}
          watched={watched}
          favourites={favourites}
          removeFavourite={removeFavourite}
          addFavourite={addFavourite}
        />
      )}

      {view === 'thumbnail' && (
        <ThumbnailView
          isLoading={isLoading}
          isNoResult={isNoResult}
          media={media}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          isSubscribed={isSubscribed}
          watched={watched}
          favourites={favourites}
          removeFavourite={removeFavourite}
          addFavourite={addFavourite}
        />
      )}
    </div>
  );
};

export default Media;
