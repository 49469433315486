import { Fragment, useCallback, useMemo } from 'react';
import CONFIG from '../../../config/config.json';
import './play.css';
import SVGLocked from '../Media/Lock';
import Creator from './Creator';
import DownloadButton from '../DownloadButton';

const { API_URL } = CONFIG;

/* import { Helmet } from 'react-helmet'; */

/* const isVideoPlaying = (video) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  );
 */
const Play = ({ selectedMedia, handleWatched, isSubscribed }) => {
  const isVideoVisible = useMemo(
    () => selectedMedia != null && selectedMedia.type === 'video',
    [selectedMedia]
  );

  const handleVideoEnded = useCallback(() => {
    if (
      selectedMedia.isFree === true ||
      (selectedMedia.isFree === false && isSubscribed)
    ) {
      handleWatched(selectedMedia.id);
    }
    document.querySelector('#player').play();
  }, [selectedMedia, handleWatched, isSubscribed]);

  //TODO: if browser is visible
  //https://developers.facebook.com/docs/sharing/webmasters/
  // <meta property="og:site_name" content="image" />
  //<meta property="og:description"        content="How much does culture influence creative thinking?" />
  return (
    <Fragment>
      {/* 
      Commented, bc its not working anyways
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="movie" />
        <meta
          property="og:title"
          content={`Lineups.fun - ${selectedMedia.title}`}
        />
        <meta
          property="og:video"
          content={
            selectedMedia.source != null
              ? selectedMedia.source
              : `${API_URL}/get/video/${selectedMedia.id}`
          }
        />
        <meta
          property="og:video:secure_url"
          content={
            selectedMedia.source != null
              ? selectedMedia.source
              : `${API_URL}/get/video/${selectedMedia.id}`
          }
        />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video:width" content="1920" />
        <meta property="og:video:height" content="1080" />
        <meta property="og:site_name" content="Lineups.fun" />
      </Helmet> */}
      <div className="widget play">
        {selectedMedia.isFree === false && isSubscribed === false && (
          <div className="subscribe">
            <div className="sub-line">
              <SVGLocked />
              <p>Subscriber only content</p>
              <DownloadButton />
            </div>
          </div>
        )}
        <div
          className={`play-content ${
            selectedMedia.isFree || isSubscribed ? `` : `locked`
          }`}
        >
          {isVideoVisible && <Creator creator={selectedMedia.author} />}
          {isVideoVisible && (
            <video
              id="player"
              controlsList="nodownload noremoteplayback"
              key={selectedMedia.id}
              muted={true}
              autoPlay={true}
              controls
              playsInline={true}
              preload="true"
              onEnded={handleVideoEnded}
            >
              {selectedMedia.source != null ? (
                <source src={selectedMedia.source} type="video/mp4" />
              ) : (
                <source
                  src={`${API_URL}/get/video/${selectedMedia.id}`}
                  type="video/mp4"
                />
              )}
            </video>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Play;
