import ReactGA from 'react-ga';
import MouseOutside from '../MouseOutside';
import NavArrow from '../NavArrow';
import { useState, useMemo, useCallback, createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Toggle from '../Toggle';
import Checkbox from '../Checkbox';
import { capitalize, generateHomePageUrl } from '../utils';
import './filters.css';
import {
  getEveryAgent,
  getEveryMap,
  getAgentDetailsByEnglishName,
} from 'valorant-tools';

const getAbilitySummary = (agent, ability) => {
  if (ability === 'all') {
    return capitalize(ability, true);
  } else if (ability === 'setups') {
    return capitalize('Setups and Executes', true);
  } else {
    const agentDetails = getAgentDetailsByEnglishName(agent);

    if (agentDetails != null) {
      const abilityDetails = agentDetails.abilities.find(
        ({ key }) => key === ability
      );
      return `${capitalize(ability, true)} - ${capitalize(
        abilityDetails.label,
        true
      )}`;
    }
    return ability;
  }
};

const Filters = ({
  searchParams,
  toggleSidebar,
  agent,
  map,
  ability,
  side,
  onSideChange,
  showFavOnly,
  onShowFavChange,
  position,
  onPositionChange,
}) => {
  const [menuOpen, setMenuOpen] = useState('');

  const closeMenu = useCallback(() => {
    setMenuOpen('');
  }, [setMenuOpen]);

  const refAgent = createRef();
  const refMap = createRef();
  const refAbilities = createRef();

  const allMaps = useMemo(() => getEveryMap(), []);
  const allAgents = useMemo(() => getEveryAgent(), []);

  const toggleMenu = useCallback(
    (menu) => {
      if (menu === menuOpen) {
        closeMenu();
      } else {
        setMenuOpen(menu);
      }
    },
    [menuOpen, closeMenu, setMenuOpen]
  );

  const abilitySummary = useMemo(
    () => getAbilitySummary(agent, ability),
    [ability, agent]
  );

  const abilityOptions = useMemo(() => {
    const agentDetails = getAgentDetailsByEnglishName(agent);
    if (agentDetails != null) {
      return agentDetails.abilities;
    }
    return [];
  }, [agent]);

  return (
    <Fragment>
      <div className="widget filter-text">
        <p>Filters</p>
        <div className="btn">
          <button onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
            >
              <path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z" />
            </svg>
            <span>Select Media</span>
          </button>
        </div>
      </div>
      <div className="widget filterwidget nav">
        <MouseOutside
          onClickOutside={menuOpen === 'agent' ? closeMenu : () => {}}
          refs={[refAgent]}
        >
          {() => (
            <div
              ref={refAgent}
              className={`dropdown-filter sublist-holder ${
                menuOpen === 'agent' ? 'open' : ''
              }`}
              onClick={() => toggleMenu('agent')}
            >
              <div className="header-row">
                <div>
                  <NavArrow className="nav-arrow" />
                  <p>Agent</p>
                </div>
                <div>
                  <p>{agent}</p>
                </div>
              </div>
              <ul className="sublist linked">
                {allAgents.map((a) => (
                  <li
                    key={a}
                    onClick={(e) => {
                      closeMenu();
                      ReactGA.event({
                        category: 'User',
                        action: 'User selected agent.',
                        label: a,
                      });
                      e.stopPropagation();
                    }}
                    className={a === agent ? 'active' : 'y'}
                  >
                    <Link
                      to={generateHomePageUrl(a, map, ability, searchParams)}
                    >
                      {a}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </MouseOutside>
        <MouseOutside
          onClickOutside={menuOpen === 'map' ? closeMenu : () => {}}
          refs={[refMap]}
        >
          {() => (
            <div
              ref={refMap}
              className={`dropdown-filter sublist-holder ${
                menuOpen === 'map' ? 'open' : ''
              }`}
              onClick={() => toggleMenu('map')}
            >
              <div className="header-row">
                <div>
                  <NavArrow className="nav-arrow" />
                  <p>Map</p>
                </div>
                <div>
                  <p>{map}</p>
                </div>
              </div>
              <ul className="sublist linked">
                {allMaps.map((m) => (
                  <li
                    key={m}
                    onClick={(e) => {
                      closeMenu();
                      ReactGA.event({
                        category: 'User',
                        action: 'User selected map.',
                        label: m,
                      });
                      e.stopPropagation();
                    }}
                    className={m === map ? 'active' : ''}
                  >
                    <Link
                      to={generateHomePageUrl(agent, m, ability, searchParams)}
                    >
                      {m}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </MouseOutside>
        <MouseOutside
          onClickOutside={menuOpen === 'ability' ? closeMenu : () => {}}
          refs={[refAbilities]}
        >
          {() => (
            <div
              ref={refAbilities}
              className={`dropdown-filter sublist-holder ${
                menuOpen === 'ability' ? 'open' : ''
              }`}
              onClick={() => toggleMenu('ability')}
            >
              <div className="header-row">
                <div>
                  <NavArrow className="nav-arrow" />
                  <p>Abilities</p>
                </div>
                <div>
                  <p>{abilitySummary}</p>
                </div>
              </div>

              <ul className="sublist linked">
                <li
                  className={ability === 'all' ? 'active' : ''}
                  onClick={(e) => {
                    closeMenu();
                    ReactGA.event({
                      category: 'User',
                      action: 'User selected ability.',
                      label: ability,
                    });
                    e.stopPropagation();
                  }}
                >
                  <Link
                    to={generateHomePageUrl(agent, map, 'all', searchParams)}
                  >
                    All
                  </Link>
                </li>
                {abilityOptions.map(({ key, label }) => (
                  <li
                    key={key}
                    onClick={(e) => {
                      closeMenu();
                      ReactGA.event({
                        category: 'User',
                        action: 'User selected ability.',
                        label: key,
                      });
                      e.stopPropagation();
                    }}
                    className={key === ability ? 'active' : ''}
                  >
                    <Link
                      to={generateHomePageUrl(agent, map, key, searchParams)}
                    >
                      {key} - {capitalize(label, true)}
                    </Link>
                  </li>
                ))}
                <li
                  className={ability === 'setups' ? 'active' : ''}
                  onClick={(e) => {
                    closeMenu();
                    ReactGA.event({
                      category: 'User',
                      action: 'User selected ability.',
                      label: ability,
                    });
                    e.stopPropagation();
                  }}
                >
                  <Link
                    to={generateHomePageUrl(agent, map, 'setups', searchParams)}
                  >
                    Setups and Executes
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </MouseOutside>
      </div>
      <div className="widget filters">
        <div className="sides">
          <Toggle
            onChange={onSideChange}
            active={side}
            items={[
              {
                label: 'All',
                name: 'all',
              },
              {
                label: 'Attack',
                name: 'attack',
              },
              {
                label: 'Defense',
                name: 'defense',
              },
            ]}
          />
        </div>
        <div className="position">
          <Toggle
            onChange={onPositionChange}
            active={position}
            items={[
              {
                label: 'All',
                name: 'all',
              },
              {
                label: 'A Site',
                name: 'A',
              },
              {
                label: 'B Site',
                name: 'B',
              },
              {
                label: 'C Site',
                name: 'C',
              },
              {
                label: 'Mid',
                name: 'Mid',
              },
            ]}
          />
        </div>
        <div className={`favs ${showFavOnly && 'checked'}`}>
          <Checkbox checked={showFavOnly} onChange={onShowFavChange} />
          <p onClick={onShowFavChange}>Favourites Only</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Filters;
