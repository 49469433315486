import React, { useState, useMemo, useCallback, createRef } from 'react';
import DownloadButton from '../DownloadButton';
import './header.css';
import NavArrow from '../NavArrow';
import MouseOutside from '../MouseOutside';
/* import { useAuthService } from '../Auth'; */
import { getEveryAgent /* , getEveryMap  */ } from 'valorant-tools';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState('');
  const closeMenu = useCallback(() => {
    setMenuOpen('');
  }, [setMenuOpen]);
  const refMenu1 = createRef();
  const refMenu2 = createRef();
  const refMenu3 = createRef();

  const toggleMenu = useCallback(
    (menu) => {
      if (menu === menuOpen) {
        closeMenu();
      } else {
        setMenuOpen(menu);
      }
    },
    [menuOpen, closeMenu, setMenuOpen]
  );
  /*   const { isLoading, logout, isAuthenticated, user } = useAuthService();

     const logoutText = useMemo(() => {
    if (isLoading === false && isAuthenticated && user != null) {
      return `Logout ${user.username}`;
    }
    return 'Logout';
  }, [isLoading, isAuthenticated, user]);  */

  const agentsNav = useMemo(
    () =>
      getEveryAgent().map((agent) => ({
        link: `/agent/${encodeURIComponent(agent)}`,
        displayName: agent,
      })),
    []
  );

  /*   const mapsNav = useMemo(
    () =>
      getEveryMap().map((map) => ({
        link: `/map/${encodeURIComponent(map)}`,
        displayName: map,
      })),
    []
  ); */

  return (
    <header className="widget header overwolf grabbable">
      <Logo />
      <nav className="nav">
        <ul>
          {/* <MouseOutside
            key="options"
            onClickOutside={menuOpen === 'options' ? closeMenu : () => {}}
            refs={[refMenu]}
          >
            {() => (
              <li
                ref={refMenu}
                className={`sublist-holder ${
                  menuOpen === 'options' ? 'open' : ''
                }`}
                onClick={() => toggleMenu('options')}
              >
                <p>Navigation</p>
                <NavArrow className="nav-arrow" />
                <ul className="sublist linked">
                  {isAuthenticated && (
                    <li>
                      <Link to="/">App</Link>
                    </li>
                  )}
                  {isAuthenticated && (
                    <li onClick={logout} className="padded">
                      {logoutText}
                    </li>
                  )}

                  {isAuthenticated === false && (
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  )}
                  {isAuthenticated === false && (
                    <li>
                      <Link to="/signup">Sign up</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </MouseOutside> */}
          <MouseOutside
            key="agents"
            onClickOutside={menuOpen === 'agents' ? closeMenu : () => {}}
            refs={[refMenu1]}
          >
            {() => (
              <li
                ref={refMenu1}
                className={`sublist-holder ${
                  menuOpen === 'agents' ? 'open' : ''
                }`}
                onClick={() => toggleMenu('agents')}
              >
                <p>Agents Info</p>
                <NavArrow className="nav-arrow" />
                <ul className="sublist linked">
                  {agentsNav.map(({ displayName, link }) => (
                    <li key={displayName}>
                      <Link to={link}>{displayName}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </MouseOutside>
          {/* <MouseOutside
            key="maps"
            onClickOutside={menuOpen === 'maps' ? closeMenu : () => {}}
            refs={[refMenu]}
          >
            {() => (
              <li
                ref={refMenu}
                className={`sublist-holder ${
                  menuOpen === 'maps' ? 'open' : ''
                }`}
                onClick={() => toggleMenu('maps')}
              >
                <p>Maps</p>
                <NavArrow className="nav-arrow" />
                <ul className="sublist linked">
                  {mapsNav.map(({ displayName, link }) => (
                    <li key={displayName}>
                      <Link to={link}>{displayName}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </MouseOutside> */}
          <MouseOutside
            key="valorant"
            onClickOutside={menuOpen === 'valorant' ? closeMenu : () => {}}
            refs={[refMenu2]}
          >
            {() => (
              <li
                ref={refMenu2}
                className={`sublist-holder ${
                  menuOpen === 'valorant' ? 'open' : ''
                }`}
                onClick={(e) => toggleMenu('valorant')}
              >
                <p>Valorant</p>
                <NavArrow className="nav-arrow" />
                <ul className="sublist linked">
                  <li key="weapons">
                    <Link to="/page/weapons">Weapons</Link>
                  </li>
                  <li key="economy">
                    <Link to="/page/economy">Economy</Link>
                  </li>
                </ul>
              </li>
            )}
          </MouseOutside>
          <MouseOutside
            key="other"
            onClickOutside={menuOpen === 'other' ? closeMenu : () => {}}
            refs={[refMenu3]}
          >
            {() => (
              <li
                ref={refMenu3}
                className={`sublist-holder ${
                  menuOpen === 'other' ? 'open' : ''
                }`}
                onClick={() => toggleMenu('other')}
              >
                <p>Other</p>
                <NavArrow className="nav-arrow" />
                <ul className="sublist linked">
                  <li>
                    <a
                      href="https://upload.lineups.fun/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Upload your Lineup
                    </a>
                  </li>
                  <li>
                    <Link to="/page/qa">Q &amp; A</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use">Terms of Use</Link>
                  </li>
                </ul>
              </li>
            )}
          </MouseOutside>
        </ul>
      </nav>
      <div className="hotkeys"></div>
      <div className="download-button-holder">
        <DownloadButton />
      </div>
      <div className="control-buttons-holder">
        <div className="discord">
          <a
            title="Join or Discord server"
            href="https://discord.gg/RARcPbVApn"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="22"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="discord"
              role="img"
              viewBox="0 0 640 512"
            >
              <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
            </svg>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
