import * as React from 'react';
import { conditionalDebounce } from './debounce';

function isElement(target) {
  return (
    target instanceof HTMLElement ||
    target instanceof HTMLDocument ||
    target instanceof SVGSVGElement
  );
}

function filterInvalidRefs(refs) {
  return refs.filter((ref) => {
    return ref.current && ref.current instanceof HTMLElement;
  });
}
function isTargetOutside(ref, target) {
  return ref.current.contains(target) === false;
}

export default class MouseOutside extends React.Component {
  static defaultProps = {
    delay: 0,
    refs: [],
  };

  container = React.createRef();

  areTargetsOutside = (target) => {
    const refs = [this.container, ...this.props.refs];
    if (isElement(target) && document.contains(target)) {
      return filterInvalidRefs(refs).every((ref) =>
        isTargetOutside(ref, target)
      );
    }
    return false;
  };

  handleClickOutside = (evt) => {
    if (this.props.onClickOutside && this.areTargetsOutside(evt.target)) {
      this.props.onClickOutside(evt);
    }
  };

  handleMoveOutside = conditionalDebounce((evt) => {
    if (this.props.onMoveOutside && this.areTargetsOutside(evt.target)) {
      this.props.onMoveOutside(evt);
    }
  }, this.props.delay);

  componentDidMount() {
    if (this.props.onClickOutside) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
    if (this.props.onMoveOutside) {
      document.addEventListener('mousemove', this.handleMoveOutside, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('mousemove', this.handleMoveOutside, true);
  }

  render() {
    return this.props.children(this.container);
  }
}
