import React, { useContext } from 'react';

const AuthServiceContext = React.createContext({
  isLoading: true,
  isAuthenticated: false,
  token: null,
  user: null,
  callback: () => {},
  login: () => {},
  logout: () => new Promise(() => undefined),
});

const useAuthService = () => useContext(AuthServiceContext);

export default AuthServiceContext;

export { useAuthService };
