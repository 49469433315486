import React, { useMemo } from 'react';
import { getAgentDetailsByEnglishName } from 'valorant-tools';
import './agentinfo.css';
import { Link } from 'react-router-dom';

const AgentInfo = ({ agent, map }) => {
  const agentDetails = useMemo(
    () => getAgentDetailsByEnglishName(agent),
    [agent]
  );

  return (
    <div className="widget agentinfo">
      <div className="profile">
        <div>
          <img src={agentDetails.icon} alt="agent avatar" />
        </div>
        <div className="infos">
          <div>
            <h1>{agentDetails.en}</h1>
            <h3 className="description">{agentDetails.description}</h3>
          </div>
          <div>
            <h1 className="role">{agentDetails.role}</h1>
            <h3 className="description">{agentDetails.roleDescription}</h3>
          </div>
        </div>
      </div>
      <div className="abilities">
        {agentDetails.abilities.map((ability) => (
          <div key={`${encodeURI(agent)}-${ability.key.toLowerCase()}`}>
            <div className="avatar-holder">
              <div
                className={`ab-img ${ability.key.toLowerCase()}`}
                style={{
                  backgroundImage: `url(${ability.img})`,
                }}
              ></div>
            </div>
            <div>
              <h3>
                {ability.key} - {ability.label}
              </h3>
              <p>{ability.description}</p>
              <h3 className="link">
                <Link
                  to={`/${encodeURIComponent(agent)}/${map}/${ability.key}`}
                >
                  {agentDetails.en} {ability.label.toLowerCase()} lineups videos
                  for {map}
                </Link>
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentInfo;
