const Toggle = ({ active, onChange, items }) => {
  return (
    <ul className="toggle">
      {items.map(({ name, label }) => (
        <li
          onClick={() => onChange(name)}
          key={name}
          className={active === name ? 'active' : ''}
        >
          <label>
            <p>{label}</p>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default Toggle;
