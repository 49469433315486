import React from 'react';
import AuthServiceContext, { useAuthService } from './AuthServiceContext';
import useAuthSericeHook from './useAuthService';

const AuthServiceProvider = ({ children, ...props }) => {
  const { isLoading, isAuthenticated, token, callback, login, logout, user } =
    useAuthSericeHook();
  return (
    <AuthServiceContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        token,
        callback,
        login,
        logout,
        user,
      }}
    >
      {children}
    </AuthServiceContext.Provider>
  );
};

export default AuthServiceProvider;
export { useAuthService };
