import React from 'react';
import ReactDOM from 'react-dom';
import PageHome from './screens/home';
import PageAdblock from './screens/adblock';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AdBlockDetectedWrapper } from 'adblock-detect-react';

ReactDOM.render(
  <React.StrictMode>
    <div className="app">
      <AdBlockDetectedWrapper>
        <PageAdblock />
      </AdBlockDetectedWrapper>
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <PageHome />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
