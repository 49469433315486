import React, { useCallback } from 'react';
import ReactGA from 'react-ga';
import IMG_Overwolf from './overwolf.svg';
import './sub.css';

const DownloadButton = ({ isSubscribed }) => {
  const handleDownload = useCallback(() => {
    ReactGA.event({
      category: 'User',
      action: 'User clicked on download ovewolf app button.',
    });
  }, []);

  return (
    <div className="widget download-btn">
      <a
        href="https://www.overwolf.com/app/Gabriel_Micko-Valorant_Lineups"
        target="_blank"
        rel="noreferrer"
        onClick={handleDownload}
      >
        <div className="sub">
          <img className="unlock" src={IMG_Overwolf} alt="unlock" />
          Download our Desktop App
        </div>
      </a>
    </div>
  );
};

export default DownloadButton;
