import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Header/Logo';
import './footer.css';
import DownloadButton from '../DownloadButton';
import { getEveryAgent } from 'valorant-tools';

const Footer = ({ map }) => {
  const agents = useMemo(() => getEveryAgent(), []);
  const agentsHalf = useMemo(() => Math.ceil(agents.length / 2), [agents]);
  const firstHalfAgents = useMemo(
    () => agents.splice(0, agentsHalf),
    [agentsHalf, agents]
  );
  const secondHalfAgents = useMemo(
    () => agents.splice(-agentsHalf),
    [agentsHalf, agents]
  );

  return (
    <footer className="widget footer">
      <div>
        <Logo />
      </div>
      <div className="contact">
        <p>
          Have feedback? Join our{' '}
          <a
            title="Join or Discord server"
            href="https://discord.gg/RARcPbVApn"
            target="_blank"
            rel="noreferrer"
          >
            discord
          </a>{' '}
          and let us know.
        </p>
      </div>
      <div className="download-button-holder">
        <DownloadButton />
      </div>
      <div className="lists">
        <div>
          <ul className="agent-links">
            {firstHalfAgents.map((agent, index) => (
              <li key={`fh-${index}`}>
                <Link to={`/${encodeURIComponent(agent)}/Ascent/all`}>
                  {agent} lineups
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul className="agent-links">
            {secondHalfAgents.map((agent, index) => (
              <li key={`fh-${index}`}>
                <Link to={`/${encodeURIComponent(agent)}/Ascent/all`}>
                  {agent} lineups
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <Link to="/page/weapons">Valorant Weapons</Link>
            </li>
            <li>
              <Link to="/page/economy">Valorant Economy</Link>
            </li>
            <li>
              <Link to="/page/qa">Questions and Answers</Link>
            </li>
            <li>
              <Link to="/about-us">About us</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms of use</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
