import { Fragment } from 'react';
import VideoLoading from '../VideoLoading';
import Favourite from '../Favourite';
import {
  getPositionLabel,
  getAgentAbilityImage,
  getAgentMediaAbility,
} from './utils';
import SVGLock from './Lock.jsx';
import IMG_Triangle from '../triangle.svg';
import './listview.css';

const ListView = ({
  isLoading,
  isNoResult,
  media,
  selectedId,
  setSelectedId,
  isSubscribed,
  watched,
  favourites,
  removeFavourite,
  addFavourite,
}) => (
  <ul className="listview">
    {(isLoading || isNoResult) && (
      <Fragment>
        {isLoading && <VideoLoading />}
        {isLoading === false && isNoResult && (
          <p className="no-media">
            No media found.
            <br />
            Please change your filters.
          </p>
        )}
      </Fragment>
    )}
    {isLoading === false &&
      isNoResult === false &&
      media.map(
        ({ id, ability, agent, title, isFree, side, position }, index) => (
          <li
            className={id === selectedId ? `active` : ``}
            key={`${agent}-${index}`}
            onClick={() => setSelectedId(id)}
          >
            <div
              className={`locked-content ${
                isFree || isSubscribed ? '' : 'locked'
              }`}
            >
              <div className="img-holder">
                <SVGLock />
                <p className="sub-only">Subscriber only content</p>
              </div>
            </div>
            <div
              className={`li-content ${isFree || isSubscribed ? '' : 'locked'}`}
            >
              <div className="ability">
                {watched.includes(id) === false && (
                  <div className="new-media">NEW</div>
                )}
                <div
                  className={`ab-img ${ability.toLowerCase()}`}
                  style={{
                    backgroundImage: `url(${getAgentAbilityImage(
                      agent,
                      ability
                    )})`,
                  }}
                ></div>
              </div>
              <div className="text">
                <h3 title={title}>{title}</h3>
                <p>
                  {getAgentMediaAbility({
                    agent,
                    ability,
                  })}
                  , {getPositionLabel(position)},{' '}
                  {side === 'attack' ? 'Attack' : 'Defense'}
                </p>
              </div>
              <div className="is-fav">
                <Favourite
                  title="Favourite"
                  isFavourite={favourites.includes(id)}
                  onClick={
                    favourites.includes(id)
                      ? () => removeFavourite(id)
                      : () => addFavourite(id)
                  }
                />
              </div>
              {watched.includes(id) === false && (
                <img
                  title="Never seen"
                  className="triangle"
                  src={IMG_Triangle}
                  alt="triangle"
                />
              )}
            </div>
          </li>
        )
      )}
  </ul>
);

export default ListView;
