import './ad.css';
import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

const ADS = {
  '320x200': {
    style: {
      display: 'block',
      width: '320px',
      height: '200px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '1782778358',
  },
  '400x200': {
    style: {
      display: 'block',
      width: '400px',
      height: '200px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '6961284540',
  },
  '800x200': {
    style: {
      display: 'block',
      width: '800px',
      height: '200px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '9461123677',
  },
  '1200x200': {
    style: {
      display: 'block',
      width: '1200px',
      height: '200px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '1774985595',
  },
  '320x90': {
    style: {
      display: 'block',
      width: '320px',
      height: '90px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '6105166740',
  },
  '400x90': {
    style: {
      display: 'block',
      width: '400px',
      height: '90px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '4983656762',
  },
  '800x90': {
    style: {
      display: 'block',
      width: '800px',
      height: '90px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '6488310120',
  },
  '400x300': {
    style: {
      display: 'inline-block',
      width: '400px',
      height: '300px',
    },
    'data-ad-client': 'ca-pub-7666627858951769',
    'data-ad-slot': '8057876858',
  },
};

const AdContainer = ({
  isLoadingAd,
  isBorderless = false,
  maxAdType = 100,
  adFormat = 'normal',
  forceAd,
}) => {
  const isAd4 = useMediaQuery({ query: '(min-width: 1200px)' });
  const isAd3 = useMediaQuery({ query: '(max-width: 1200px)' });
  const isAd2 = useMediaQuery({ query: '(max-width: 800px)' });
  const isAd1 = useMediaQuery({ query: '(max-width: 400px)' });

  const adType = useMemo(() => {
    let type = 1;
    if (isAd4) {
      type = 4;
    } else if (isAd1) {
      type = 1;
    } else if (isAd2) {
      type = 2;
    } else if (isAd3) {
      type = 3;
    }
    const mins = [maxAdType, type];
    return Math.min(...mins);
  }, [isAd1, isAd2, isAd3, isAd4, maxAdType]);

  const adProps = useMemo(() => {
    if (forceAd != null) {
      return ADS[forceAd];
    }
    if (adType === 1 && adFormat === 'normal') {
      return ADS['320x200'];
    }
    if (adType === 2 && adFormat === 'normal') {
      return ADS['400x200'];
    }
    if (adType === 3 && adFormat === 'normal') {
      return ADS['800x200'];
    }
    if (adType === 4 && adFormat === 'normal') {
      return ADS['1200x200'];
    }

    if (adType === 1 && adFormat === 'narrow') {
      return ADS['320x90'];
    }
    if (adType === 2 && adFormat === 'narrow') {
      return ADS['400x90'];
    }
    if ((adType === 3 || adType === 4) && adFormat === 'narrow') {
      return ADS['800x90'];
    }
  }, [adType, forceAd, adFormat]);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [adType]);

  return (
    <div className={`widget ad ${isBorderless ? ' borderless' : ''}`}>
      <ins key={String(adType)} className="adsbygoogle" {...adProps}></ins>
    </div>
  );
};

export default AdContainer;
