import Loading from '../Loading';
import './videoloading.css';

const VideoLoading = ({ text = 'Loading media' }) => (
  <div className="widget videoloading">
    <p>{text}</p>
    <Loading />
  </div>
);

export default VideoLoading;
