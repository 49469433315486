import { Link } from 'react-router-dom';
import './logo.css';

const Logo = () => (
  <Link to="/" className="widget main-logo main-url" title="Homepage">
    <div className="title">
      <div className="dot">Lineups.fun</div>{' '}
      <div className="sl">Valorant Lineups</div>
    </div>
  </Link>
);

export default Logo;
