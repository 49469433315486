const Checkbox = ({ checked, onChange, ...props }) => {
  return (
    <label className="checkbox" {...props}>
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="#fff"
        >
          <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-5.541 8.409l-1.422-1.409-7.021 7.183-3.08-2.937-1.395 1.435 4.5 4.319 8.418-8.591z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          fill="#8fa3b0"
        >
          <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
        </svg>
      )}
      <input type="checkbox" value={checked} onChange={onChange} />
    </label>
  );
};
export default Checkbox;
