import React from 'react';
import './button.css';

const Button = ({ children, ...props }) => (
  <div className="widget button">
    <a {...props}>
      <div className="sub">{children}</div>
    </a>
  </div>
);

export default Button;
