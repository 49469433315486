import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  createRef,
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Filters from './Filters';
import Media from './Media';
import Play from './Play';
import NoMedia from './NoMedia';
import VideoLoading from './VideoLoading';
import { fetchMedia } from './utils';
import useLocalStorage from './useLocalStorage';
import MouseOutside from './MouseOutside';
import AgentInfo from './Footer/AgentInfo';
import Ad from './Ad';
import { useMediaQuery } from 'react-responsive';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PageHome = () => {
  const location = useLocation();
  const params = useParams();
  const searchParams = useQuery();
  const history = useHistory();
  const { map, agent, ability, side, position, selectedId } = useMemo(
    () => ({
      map: params.hasOwnProperty('map')
        ? decodeURIComponent(params.map)
        : 'Ascent',
      agent: params.hasOwnProperty('agent')
        ? decodeURIComponent(params.agent)
        : 'Viper',
      ability: params.hasOwnProperty('ability')
        ? decodeURIComponent(params.ability)
        : 'all',
      side: searchParams.get('side') != null ? searchParams.get('side') : 'all',
      position:
        searchParams.get('position') != null
          ? searchParams.get('position')
          : 'all',
      selectedId:
        searchParams.get('mediaId') != null
          ? searchParams.get('mediaId')
          : undefined,
    }),
    [params, searchParams]
  );

  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFavOnly, setFavOnly] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [favourites, setFavourites] = useLocalStorage('favourites', []);
  const [watched, setWatched] = useLocalStorage('watched', []);

  const isSubscribed = true;

  const handleSelectedChange = useCallback(
    (mediaId) => {
      if (mediaId != null) {
        searchParams.set('mediaId', mediaId);
        history.replace({ search: searchParams.toString() });
      } else {
        searchParams.delete('mediaId');
        history.replace({ search: searchParams.toString() });
      }
    },
    [searchParams, history]
  );

  const handleSideChange = useCallback(
    (side) => {
      console.log('SIDE', side);
      searchParams.set('side', side);
      history.replace({ search: searchParams.toString() });
      ReactGA.event({
        category: 'User',
        action: 'User selected side.',
        label: side,
      });
    },
    [searchParams, history]
  );
  const handlePositionChange = useCallback(
    (position) => {
      searchParams.set('position', position);
      history.replace({ search: searchParams.toString() });
      ReactGA.event({
        category: 'User',
        action: 'User selected position.',
        label: position,
      });
    },
    [searchParams, history]
  );

  const handleFavOnlyToggle = useCallback(() => {
    setFavOnly(!showFavOnly);
    ReactGA.event({
      category: 'User',
      action: 'User toggled favourites only.',
      label: String(!showFavOnly),
    });
  }, [setFavOnly, showFavOnly]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMedia(map, agent, ability);
      if (data != null && data.media != null && data.media.length > 0) {
        setMedia(data.media);
      } else {
        setMedia([]);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchData();
  }, [map, agent, ability, side, setIsLoading, setMedia]);

  const filteredMedia = useMemo(() => {
    let fm = media;
    if (showFavOnly) {
      fm = fm.filter(({ id }) => favourites.includes(id));
    }
    if (side === 'attack' || side === 'defense') {
      fm = fm.filter((item) => item.side === side);
    }
    if (['A', 'B', 'C', 'Mid'].includes(position)) {
      fm = fm.filter((item) => item.position === position);
    }
    return fm;
  }, [media, favourites, showFavOnly, side, position]);

  const selectedMedia = useMemo(() => {
    const foundMedia = filteredMedia.find(({ id }) => id === selectedId);
    if (foundMedia != null) {
      return foundMedia;
    }
    return [];
  }, [selectedId, filteredMedia]);

  const handleWatched = useCallback(
    (id) => {
      if (watched.includes(id) === false) {
        setWatched([...watched, id]);
        ReactGA.event({
          category: 'User',
          action: 'User watched watched a video for the first time.',
          label: id,
        });
      } else {
        ReactGA.event({
          category: 'User',
          action: 'User watched a video more than once.',
          label: id,
        });
      }
    },
    [watched, setWatched]
  );

  useEffect(() => {
    if (isLoading === false) {
      if (filteredMedia.length > 0) {
        const hasId = filteredMedia.some(({ id }) => id === selectedId);
        if (hasId === false) {
          handleSelectedChange(filteredMedia[0].id);
        }
      } else {
        handleSelectedChange();
      }
    }
  }, [filteredMedia, selectedId, handleSelectedChange, isLoading]);

  const addFavourite = useCallback(
    (id) => {
      ReactGA.event({
        category: 'User',
        action: 'User added video to favourites.',
      });
      setFavourites([...favourites, id]);
    },
    [setFavourites, favourites]
  );

  const removeFavourite = useCallback(
    (rmId) => {
      ReactGA.event({
        category: 'User',
        action: 'User removed video from favourites.',
      });
      setFavourites([...favourites.filter((id) => id !== rmId)]);
    },
    [setFavourites, favourites]
  );

  const isNoResult = useMemo(
    () => isLoading === false && filteredMedia.length === 0,
    [isLoading, filteredMedia]
  );

  const toggleSidebar = useCallback(() => {
    ReactGA.event({
      category: 'User',
      action: 'User opened the sidebar.',
    });
    setIsSidebarOpen(!isSidebarOpen);
  }, [setIsSidebarOpen, isSidebarOpen]);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  const handleSetSelected = useCallback(
    (id) => {
      if (selectedId !== id) {
        handleSelectedChange(id);
        setIsSidebarOpen(false);
      }
    },
    [selectedId, handleSelectedChange, setIsSidebarOpen]
  );

  const refSidebar = createRef();
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Fragment>
      <div className="content page home">
        <div className="main">
          <div className="flex">
            <Filters
              toggleSidebar={toggleSidebar}
              agent={agent}
              map={map}
              ability={ability}
              onSideChange={handleSideChange}
              side={side}
              showFavOnly={showFavOnly}
              onShowFavChange={handleFavOnlyToggle}
              searchParams={searchParams}
              position={position}
              onPositionChange={handlePositionChange}
            />
          </div>
          <div className="grow">
            {isMobile && <Ad adFormat="narrow" isBorderless={true} />}
            {isLoading === false && isNoResult && <NoMedia agent={agent} />}
            {isLoading && <VideoLoading isNoResult={isNoResult} />}
            {isLoading === false && isNoResult === false && (
              <Play
                isSubscribed={isSubscribed}
                selectedMedia={selectedMedia}
                handleWatched={handleWatched}
              />
            )}
          </div>
        </div>
        <div className={`sidebar secondary${isSidebarOpen ? ` open` : ``}`}>
          <MouseOutside
            key="sidebar"
            onClickOutside={isSidebarOpen ? toggleSidebar : () => {}}
            refs={[refSidebar]}
          >
            {() => (
              <div ref={refSidebar} className="grow widget-media-holder">
                <Media
                  toggleSidebar={toggleSidebar}
                  isSubscribed={isSubscribed}
                  isLoading={isLoading}
                  isNoResult={isNoResult}
                  media={filteredMedia}
                  addFavourite={addFavourite}
                  removeFavourite={removeFavourite}
                  selectedId={selectedId}
                  setSelectedId={handleSetSelected}
                  favourites={favourites}
                  watched={watched}
                />
              </div>
            )}
          </MouseOutside>
          {isMobile === false && (
            <div className="flex">
              <Ad adFormat="narrow" forceAd="400x300" isBorderless={true} />
            </div>
          )}
        </div>
      </div>
      <AgentInfo agent={agent} map={map} />
      <Ad adFormat="narrow" isBorderless={true} />
    </Fragment>
  );
};

export default PageHome;
