import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAgentDetailsByEnglishName } from 'valorant-tools';
import Ad from '../Ad';
import './pageagents.css';

const PageAgents = () => {
  const params = useParams();
  const agent = params.agent;

  const agentDetails = useMemo(
    () => getAgentDetailsByEnglishName(decodeURIComponent(agent)),
    [agent]
  );

  return (
    <div className="page agents">
      <div className="profile">
        <div>
          <img src={agentDetails.icon} alt="agent avatar" />
        </div>
        <div className="infos">
          <div>
            <h1>{agentDetails.en}</h1>
            <h3 className="description">{agentDetails.description}</h3>
          </div>
          <div>
            <h1 className="role">{agentDetails.role}</h1>
            <h3 className="description">{agentDetails.roleDescription}</h3>
          </div>
        </div>
      </div>
      <Ad adFormat="narrow" isBorderless={true} />
      <div className="abilities">
        {agentDetails.abilities.map((ability) => (
          <div key={`${encodeURI(agent)}-${ability.key.toLowerCase()}`}>
            <div className="avatar-holder">
              <div
                className={`ab-img ${ability.key.toLowerCase()}`}
                style={{
                  backgroundImage: `url(${ability.img})`,
                }}
              ></div>
            </div>
            <div>
              <h3>
                {ability.key} - {ability.label}
              </h3>
              <p>{ability.description}</p>
              <h3 className="link">
                <Link
                  to={`/${encodeURIComponent(agent)}/Ascent/${ability.key}`}
                >
                  {agentDetails.en} {ability.label.toLowerCase()} lineups videos
                </Link>
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageAgents;
